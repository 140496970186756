import {useEffect, useRef} from 'react';

// Use single listener on document for performance
let needsSetup = true;
const listeners = new Set();

function setupIfNeeded() {
  if (!needsSetup) {
    return;
  }

  needsSetup = false;
  document.addEventListener('click', e => {
    for (let listener of listeners.values()) {
      listener(e);
    }
  });
}


export function useOnClickOutside(handler, elementRef, enabled = true) {
  const handlerRef = useRef(handler);

  useEffect(() =>  {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    setupIfNeeded();
    const listener = e => {
      if (!elementRef.current) {
        return;
      }

      if (elementRef.current === e.target || elementRef.current.compareDocumentPosition(e.target) & Node.DOCUMENT_POSITION_CONTAINS) {
        handlerRef.current(e);
      }
    };

    listeners.add(listener);

    return () => listeners.delete(listener);
  }, [elementRef, enabled]);
}