import "./App.css";
import { Board } from "../Board";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { JoinPage } from "../JoinPage";
import { useReducerFromObject } from "../../helpers/redux";
import { setToken } from "../../globalActions.js";
import { Token } from "../Token";
import { AppContext } from "./AppContext.js";
import "css-doodle";
import { useLayoutEffect, useRef } from "react";
import jwtDecode from "jwt-decode";

let initialToken = null;

try {
  const raw = localStorage.getItem("token");

  initialToken = jwtDecode(raw);

  if (initialToken) {
    initialToken.raw = raw;
  }

  if (initialToken?.exp <= Date.now()) {
    initialToken = null;
  }
} catch (_e) {}

function App() {
  const [state, dispatch] = useReducerFromObject(
    {
      [setToken]: (state, action) => {
        localStorage.setItem("token", action.payload);

        return {
          ...state,
          token: { ...jwtDecode(action.payload), raw: action.payload },
        };
      },
    },
    {
      token: initialToken,
    }
  );

  const backgroundRef = useRef();

  useLayoutEffect(() => {
    backgroundRef.current = document.createElement("css-doodle");

    backgroundRef.current.innerHtml = `
    :doodle {
      @grid: 20 / 100vmax;
      background: #0a0c27;
      font-family: sans-serif;
      overflow: hidden;
    }
    :after {
      content: @hex.@r(0x2500, 0x257f);
      color: hsla(@r360, 70%, 70%, @r.9);
      font-size: 8vmin;
    }
    `;
  }, []);

  return (
    <AppContext.Provider value={state}>
      <Router>
        <div className="App">
          {/*From: https://css-doodle.com/*/}
          <css-doodle
            dangerouslySetInnerHTML={{
              __html: `
                :doodle {
                  @grid: 20 / 100vmax;
                  background: #0a0c27;
                  font-family: sans-serif;
                  overflow: hidden;
                }
                :after {
                  content: \\@hex.@r(0x2500, 0x257f);
                  color: hsla(@r360, 70%, 70%, @r.9);
                  font-size: 8vmin;
                }
          `,
            }}
          />
          <Switch>
            <Route path="/token">
              <Token dispatch={dispatch} />
            </Route>
            <Route path="/play/:gameCode">
              <Board />
            </Route>
            <Route path="/">
              <JoinPage dispatch={dispatch} />
            </Route>
          </Switch>
        </div>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
