import {
  MutableRefObject,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from "react";

type ActionCreator<T> = (payload?: T) => Action<T>;
type Action<T> = { type: string; payload: T };

export function useReducerFromObject<S>(
  handlers: { [type: string]: (state: S, action: Action<any>) => S },
  // @ts-ignore
  initialState: S = {}
) {
  const currentHandlerRef: {
    current: { [key: string]: (state: S, action: Action<any>) => S };
  } = useRef(handlers);

  useEffect(() => {
    currentHandlerRef.current = handlers;
  }, [handlers]);

  return useReducer((state: S, action: Action<any>) => {
    if (currentHandlerRef.current.hasOwnProperty(action?.type)) {
      return currentHandlerRef.current[action?.type](state, action);
    }
    return state;
  }, initialState);
}

class ReducerBuilder<S> {
  cases: Map<string, (state: S, action: Action<any>) => S>;

  constructor() {
    this.cases = new Map();
  }

  addCase<T>(
    actionCreator: ActionCreator<T>,
    handler: (state: S, action: Action<T>) => S
  ): ReducerBuilder<S> {
    this.cases.set(actionCreator.toString(), handler);
    return this;
  }

  reduce<T>(state: S, action: Action<T>): S {
    const handler = this.cases.get(action.type);

    if (!handler) {
      return state;
    }

    return handler(state, action);
  }
}

export function useReducerFromBuilder<S>(
  creator: (builder: ReducerBuilder<S>) => ReducerBuilder<S>,
  initialState: S | undefined,
  init: (arg: any) => S
) {
  const currentHandlerRef: MutableRefObject<ReducerBuilder<S>> = useRef(
    creator(new ReducerBuilder<S>())
  );

  useEffect(() => {
    currentHandlerRef.current = creator(new ReducerBuilder());
  }, [creator]);

  // Seems to trigger duplicate dispatches sometimes otherwise
  const reducer = useCallback(
    (state: S, action: Action<any>) =>
      currentHandlerRef.current.reduce(state, action),
    []
  );

  return useReducer(reducer, initialState, init);
}

export function createAction<T>(name: string): ActionCreator<T> {
  const creator = (payload?: T) => ({
    type: name,
    payload,
  });

  creator.toString = () => name;

  // @ts-ignore
  return creator;
}
