import styles from "./index.module.scss";
import { useState } from "react";
import { serverURL } from "../../constants";

export function JoinPage() {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Pick Two</h1>
      <div className={styles.inner}>
        <form
          method="POST"
          action={`${serverURL}/join/${code}`}
          className={styles.form}
        >
          <input
            autoFocus
            onChange={(e) => setCode(e.target.value)}
            type="text"
            maxLength={6}
            placeholder="Code"
            className={styles.input}
          />
          <button type="submit" className={styles.join}>
            Join Game
          </button>
          {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        </form>
        <p className={styles.divider}>- or -</p>
        <a href={`${serverURL}/admin`} className={styles.create}>
          Create Game
        </a>
      </div>
    </div>
  );
}
