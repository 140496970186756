// trie with recursive format: [char, ...children], beginning of word = ^, end of word = $
export function getValidWords(originalWord, rootNode) {
  let toProcess = [{ word: originalWord, node: rootNode, track: "" }];
  let hasWildcards = false;
  let possibilities = [];

  while (toProcess.length) {
    let { word, node, track } = toProcess.shift();

    for (let i = 1; i < node.length; i++) {
      let childNode = node[i];
      if (childNode[0] === "$") {
        if (word.length) {
          continue;
        }

        possibilities.push(track);
        if (hasWildcards) {
          continue;
        }

        return possibilities;
      }

      if (word[0] === "*") {
        hasWildcards = true;

        toProcess.push({
          word: word.slice(1),
          node: childNode,
          track: track + childNode[0],
        });
        continue;
      }

      if (word[0] === childNode[0]) {
        toProcess.push({
          node: childNode,
          word: word.slice(1),
          track: track + childNode[0],
        });
        break;
      }
    }
  }

  return possibilities;
}
