import styles from "./index.module.scss";
import { selectTile } from "../Board/boardActions";
import clsx from "clsx";
import { useOnClickOutside } from "../../helpers/useOnClickOutside";
import { useRef, Dispatch } from "react";
// @ts-ignore
import { Tile as TileModel } from "@pick-two/lib/Tile.js";

export function Tile({
  tile,
  dispatch,
  selected = null,
}: {
  tile: TileModel;
  dispatch: Dispatch<any>;
  selected: string | null;
}) {
  const ref = useRef<HTMLLIElement>(null);

  let isSelected = selected === tile.id;

  useOnClickOutside(() => dispatch(selectTile(null)), ref, isSelected);

  return (
    <li
      ref={ref}
      className={clsx(styles.tile, {
        [styles.selected]: isSelected,
      })}
      onClick={() => dispatch(selectTile(tile.id))}
    >
      {tile.letter}
    </li>
  );
}
