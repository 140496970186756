import { getValidWords } from "./dictionary/index.js";
import { Tile } from "./Tile.js";

export class TileMap {
  constructor({tileMap, dictionary}) {
    this.tilesByPosition = tileMap?.tilesByPosition || new Map();
    this.positionByTileID = tileMap?.positionByTileID || new Map();
    this.errors = [];
    this.dictionary = dictionary;
  }

  add(x, y, tile) {
    const key = `${x},${y}`;

    this.errors = [];

    let currentTileAtPosition = this.get(x, y);
    const isMovingExistingTile = this.positionByTileID.has(tile.id);

    if (currentTileAtPosition) {
      if (currentTileAtPosition.id === tile.id) {
        return [this];
      }

      if (!isMovingExistingTile) {
        // return to hand
        this.tilesByPosition.set(key, tile);
        this.positionByTileID.delete(currentTileAtPosition.id);
        this.positionByTileID.set(tile.id, [x, y]);

        return [this, currentTileAtPosition];
      }

      // swap positions
      let [currentX, currentY] = this.positionByTileID.get(tile.id);

      this.positionByTileID.set(tile.id, [x, y]);
      this.positionByTileID.set(currentTileAtPosition.id, [currentX, currentY]);

      this.tilesByPosition.set(key, tile);
      this.tilesByPosition.set(
        `${currentX},${currentY}`,
        currentTileAtPosition
      );

      return [this];
    }

    if (isMovingExistingTile) {
      let [existingX, existingY] = this.positionByTileID.get(tile.id);
      let existingKey = `${existingX},${existingY}`;

      this.tilesByPosition.delete(existingKey);
    }

    this.tilesByPosition.set(key, tile);
    this.positionByTileID.set(tile.id, [x, y]);

    return [this];
  }

  remove(tileID) {
    if (!this.positionByTileID.has(tileID)) {
      return [this];
    }

    const [x, y] = this.positionByTileID.get(tileID);
    const key = `${x},${y}`;
    const tile = this.tilesByPosition.get(key);

    this.tilesByPosition.delete(key);
    this.positionByTileID.delete(tileID);

    const output = new TileMap({tileMap: this, dictionary: this.dictionary});

    return [output, tile];
  }

  get size() {
    return this.tilesByPosition.size;
  }

  get(x, y) {
    return this.tilesByPosition.get(`${x},${y}`);
  }

  getTileByID(id) {
    if (!this.positionByTileID.has(id)) {
      return null;
    }

    let [x, y] = this.positionByTileID.get(id);
    let key = `${x},${y}`;

    return this.tilesByPosition.get(key);
  }

  validate() {
    let visitedTiles = new Set(); // avoid cycles
    let toProcess = [this.tilesByPosition.values().next()?.value].filter(
      (x) => !!x
    );

    while (toProcess.length) {
      let tile = toProcess.shift();

      if (visitedTiles.has(tile.id)) {
        continue;
      }

      visitedTiles.add(tile.id);
      let [x, y] = this.positionByTileID.get(tile.id);

      const adjacent = [
        this.get(x - 1, y),
        this.get(x + 1, y),
        this.get(x, y + 1),
        this.get(x, y - 1),
      ].filter((x) => !!x);

      adjacent.forEach((x) => toProcess.push(x));
    }

    let isConnected = visitedTiles.size === this.size;

    if (!isConnected) {
      this.errors.push({
        message: "All of your tiles must be connected",
      });
    }

    let wildCardConstraints = new Map();

    for (let tile of this.tilesByPosition.values()) {
      let [x, y] = this.positionByTileID.get(tile.id);

      horizontal: if (!this.get(x - 1, y) && !!this.get(x + 1, y)) {
        // horizontal word start
        let word = tile.letter;
        let i = 1;
        let nextTile = this.get(x + i, y);

        while (!!nextTile) {
          word += nextTile.letter;
          i += 1;

          nextTile = this.get(x + i, y);
        }

        if (word.length === 1) {
          break horizontal;
        }

        const validWords = getValidWords(word.toLowerCase(), this.dictionary);

        if (!validWords.length) {
          this.errors.push({
            message: "Invalid word",
            y0: y,
            y1: y,
            x0: x,
            x1: x + i,
          });
          break horizontal;
        }

        const wildCardOptions = getWildCardOptions(word, validWords);

        for (let [index, options] of wildCardOptions.entries()) {
          const key = `${x + index},${y}`;
          const hasExistingConstraints = wildCardConstraints.has(key);

          if (
            hasExistingConstraints &&
            options.every((option) => !wildCardConstraints.get(key).has(option))
          ) {
            this.errors.push({
              message: "Invalid word",
              y0: y,
              y1: y,
              x0: x,
              x1: x + i,
            });
          }

          wildCardConstraints.set(
            key,
            hasExistingConstraints
              ? new Set(
                  options.filter((o) => wildCardConstraints.get(key).has(o))
                )
              : new Set(options)
          );
        }
      }

      vertical: if (!this.get(x, y - 1) && !!this.get(x, y + 1)) {
        // vertical word start
        let word = tile.letter;
        let i = 1;
        let nextTile = this.get(x, y + i);

        while (!!nextTile) {
          word += nextTile.letter;
          i += 1;

          nextTile = this.get(x, y + i);
        }

        if (word.length === 1) {
          break vertical;
        }

        const validWords = getValidWords(word.toLowerCase(), this.dictionary);

        if (!validWords.length) {
          this.errors.push({
            message: "Invalid word",
            y0: y,
            y1: y + i,
            x0: x,
            x1: x,
          });
          break vertical;
        }

        const wildCardOptions = getWildCardOptions(word, validWords);

        for (let [index, options] of wildCardOptions.entries()) {
          const key = `${x},${y + index}`;
          const hasExistingConstraints = wildCardConstraints.has(key);

          if (
            hasExistingConstraints &&
            options.every((option) => !wildCardConstraints.get(key).has(option))
          ) {
            this.errors.push({
              message: "Invalid word",
              y0: y,
              y1: y + i,
              x0: x,
              x1: x,
            });
          }

          wildCardConstraints.set(
            key,
            hasExistingConstraints
              ? new Set(
                  options.filter((o) => wildCardConstraints.get(key).has(o))
                )
              : new Set(options)
          );
        }
      }
    }

    return this;
  }

  hasAdjacent(x, y) {
    return (
      !!this.get(x - 1, y) ||
      !!this.get(x + 1, y) ||
      !!this.get(x, y - 1) ||
      !!this.get(x, y + 1)
    );
  }

  serialize() {
    return [...this.positionByTileID.keys()].map((id) => {
      const [x, y] = this.positionByTileID.get(id);

      return {
        id,
        x,
        y,
        ...this.tilesByPosition.get(`${x},${y}`),
      };
    });
  }

  static deserialize(serialized, dictionary, shouldValidate = true) {
    const output = new TileMap({dictionary});

    for (let { id, letter, x, y } of serialized) {
      output.positionByTileID.set(id, [x, y]);
      output.tilesByPosition.set(`${x},${y}`, new Tile(id, letter));
    }

    if (shouldValidate) {
      output.validate();
    }

    return output;
  }
}

function getWildCardOptions(word, validWords) {
  let output = new Map();
  let wildCardPosition = word.indexOf("*");

  while (wildCardPosition > -1) {
    output.set(
      wildCardPosition,
      validWords.map((x) => x[wildCardPosition].toUpperCase())
    );
    wildCardPosition = word.indexOf("*", wildCardPosition + 1);
  }

  return output;
}
