import { createAction } from "../../helpers/redux";
// @ts-ignore
import Tile from "@pick-two/lib/Tile.js";

export interface StateSync {
  remainingTiles: number;
  tiles: Tile[];
  started: boolean;
  winner?: string;
  version: number;
}

export const selectTile = createAction<string | null>("select-tile");
export const syncState = createAction<StateSync>("sync-state");
export const selectSquare = createAction<Tile>("select-square");
export const returnToHand = createAction("return-to-hand");
export const setCopyLabel = createAction<string>("set-copy-label");
export const setPlayers = createAction<string[]>("set-players");
