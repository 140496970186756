import { serverURL } from "./constants";

export async function executeStartGame(gameCode) {
  const request = await fetch(`${serverURL}/api/${gameCode}/start`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    credentials: "include",
  });

  if (!request.ok) {
    console.log("failed");
  } else {
    return true;
  }
}

export async function executeRestartGame(gameCode) {
  const request = await fetch(`${serverURL}/api/${gameCode}/restart`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    credentials: "include",
  });

  if (!request.ok) {
    console.log("failed");
  } else {
    return true;
  }
}

export async function executePickTwo(gameCode, tileMap) {
  const request = await fetch(`${serverURL}/api/${gameCode}/pick-two`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tileMap),
    credentials: "include",
  });

  if (!request.ok) {
    console.log("failed");
    return false;
  } else {
    return true;
  }
}
