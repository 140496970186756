import { useReducerFromBuilder } from "../../helpers/redux";
import { Square } from "../Square";
import styles from "./index.module.scss";
import { Tile } from "../Tile";
import {
  returnToHand,
  selectSquare,
  selectTile,
  setCopyLabel,
  setPlayers,
  syncState,
} from "./boardActions";
// @ts-ignore
import { TileMap } from "@pick-two/lib/TileMap.js";
import dictionary from "@pick-two/lib/dictionary/dictionary.json";
import {
  MutableRefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
} from "react";
// @ts-ignore
import { Tile as TileModel } from "@pick-two/lib/Tile.js";
import { useHistory, useParams } from "react-router";
import copy from "copy-to-clipboard";
import { useGlobalData } from "../../helpers/useGlobalData";
import clsx from "clsx";
import { io, Socket } from "socket.io-client";
import { serverURL } from "../../constants";
import {
  executePickTwo,
  executeRestartGame,
  executeStartGame,
} from "../../api";

const persistState = (key: string, state: BoardState): void => {
  try {
    const serialized = JSON.stringify(state, (key: string, value: any) => {
      if (value instanceof Set) {
        return {
          type: "Set",
          value: [...value.values()],
        };
      }

      if (value instanceof TileMap) {
        return { type: "TileMap", value: value.serialize() };
      }

      if (value instanceof TileModel) {
        return value.serialize();
      }

      return value;
    });

    localStorage.setItem(key, serialized);
  } catch (err) {
    console.error(err);
  }
};

function loadState(key: string): object {
  try {
    const raw = localStorage.getItem(key);

    if (!raw) {
      return {};
    }

    return JSON.parse(raw, (key: string, value: any) => {
      if (!value) {
        return value;
      }

      if (typeof value === "object" && value.type === "Set") {
        return new Set(value.value);
      }

      if (typeof value === "object" && value.type === "TileMap") {
        return TileMap.deserialize(value.value, dictionary, false);
      }

      if (typeof value === "object" && value.type === "Tile") {
        return TileModel.deserialize(value, dictionary);
      }

      return value;
    });
  } catch (err) {
    console.error(err);
    return {};
  }
}

function preventEvent(e: SyntheticEvent) {
  e.preventDefault();
}

interface BoardState {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  letters: TileModel[];
  started: boolean;
  winner?: string;
  selected: string | null;
  tiles: TileMap;
  copyLabel: string;
  players: Set<string>;
  remainingTiles: number;
  version: number;
}

export function Board() {
  const { gameCode } = useParams<{ gameCode: string }>();
  const { token } = useGlobalData();
  const history = useHistory();
  const socketRef: MutableRefObject<Socket | null> = useRef(null);

  const persistedStateKey = `game-${gameCode}`;

  const isAdmin = token?.admin === true;

  const [state, dispatch] = useReducerFromBuilder<BoardState>(
    (builder) =>
      builder
        .addCase(selectTile, (state, action) => {
          if (state.winner) {
            return state;
          }

          if (!action.payload) {
            return {
              ...state,
              selected: null,
            };
          }

          if (!state.selected) {
            return {
              ...state,
              selected: action.payload,
            };
          }

          // unselect
          if (state.selected === action.payload) {
            return { ...state, selected: null };
          }

          // from hand to board
          let sourceHandIndex = state.letters.findIndex(
            (x) => x.id === state.selected
          );
          let targetHandIndex = state.letters.findIndex(
            (x) => x.id === action.payload
          );

          const hand = [...state.letters];

          // Swapping tile from board to hand
          if (sourceHandIndex === -1 && targetHandIndex > -1) {
            const sourceTile = state.tiles.getTileByID(state.selected);
            const [x, y] = state.tiles.positionByTileID.get(state.selected);
            const handTile = hand[targetHandIndex];

            const [tiles] = state.tiles.add(x, y, handTile);

            hand[targetHandIndex] = sourceTile;

            return {
              ...state,
              selected: null,
              letters: hand,
              tiles,
            };
          }

          // Swapping from hand to hand
          const temp = hand[sourceHandIndex];
          hand[sourceHandIndex] = hand[targetHandIndex];
          hand[targetHandIndex] = temp;

          return {
            ...state,
            selected: null,
            letters: hand,
          };
        })
        .addCase(returnToHand, (state, action) => {
          let [tiles, removedTile] = state.tiles.remove(action.payload);

          if (!removedTile) {
            return state;
          }

          const hand = [...state.letters];

          hand.push(removedTile);

          return {
            ...state,
            tiles,
            letters: hand,
            selected: null,
          };
        })
        .addCase(selectSquare, (state, action) => {
          if (state.selected === null || state.winner) {
            return state;
          }

          const hand = [...state.letters];
          let selectedTile = state.tiles.getTileByID(state.selected);
          let selectedIndex = -1;

          if (!selectedTile) {
            selectedIndex = hand.findIndex((x) => x.id === state.selected);
            [selectedTile] = hand.splice(selectedIndex, 1);
          }

          const [tiles, removedTile] = state.tiles.add(
            action.payload.x,
            action.payload.y,
            selectedTile
          );

          if (removedTile) {
            if (selectedIndex > -1) {
              hand.splice(selectedIndex, 0, removedTile);
            } else {
              hand.push(removedTile);
            }
          }

          if (hand.length === 0) {
            tiles.validate();
          }

          return {
            ...state,
            tiles,
            x0: Math.min(action.payload.x, state.x0),
            x1: Math.max(action.payload.x + 1, state.x1),
            y0: Math.min(action.payload.y, state.y0),
            y1: Math.max(action.payload.y + 1, state.y1),
            letters: hand,
            selected: null,
          };
        })
        .addCase(syncState, (state, action) => {
          const { version, winner, tiles, started, remainingTiles } =
            action.payload;

          const shouldReset = version > state.version;

          const letters = tiles.reduce(
            (acc: TileModel[], x: TileModel) => {
              // skip used tiles
              if (
                state.tiles.positionByTileID.has(x.id) ||
                state.letters.find((y) => y.id === x.id)
              ) {
                return acc;
              }
              acc.push(x);
              return acc;
            },
            shouldReset ? [] : [...state.letters]
          );

          if (shouldReset) {
            return {
              x0: 0,
              y0: 0,
              x1: 1,
              y1: 1,
              letters,
              started,
              winner,
              selected: null,
              tiles: new TileMap({dictionary}),
              copyLabel: state.copyLabel,
              players: state.players,
              remainingTiles,
              version,
            };
          }

          return {
            ...state,
            version,
            letters,
            winner,
            started,
            remainingTiles,
          };
        })
        .addCase(setCopyLabel, (state, action) => ({
          ...state,
          copyLabel: action.payload,
        }))
        .addCase(setPlayers, (state, action) => ({
          ...state,
          players: new Set(action.payload),
        })),
    undefined,
    () => {
      const initialState = {
        x0: 0,
        y0: 0,
        x1: 1,
        y1: 1,
        letters: [],
        started: false,
        winner: undefined,
        selected: null,
        tiles: new TileMap({dictionary}),
        copyLabel: `Invite Code: ${gameCode}`,
        players: new Set<string>(),
        remainingTiles: 0,
        version: 0,
        ...loadState(persistedStateKey),
      };

      // @ts-ignore
      if (!(initialState.tiles instanceof TileMap)) {
        initialState.tiles = new TileMap({dictionary});
      }

      return initialState;
    }
  );

  useEffect(() => {
    // prevents errors in serializing from potentially overwriting
    if (!state.letters.length && !state.tiles.size) {
      return;
    }

    persistState(persistedStateKey, state);
  }, [persistedStateKey, state]);

  useEffect(() => {
    if (!token?.raw || token.roomCode !== gameCode) {
      history.push("/");
      return;
    }

    socketRef.current = io(serverURL, {
      auth: {
        token: token.raw,
      },
      withCredentials: true,
      upgrade: true,
      rememberUpgrade: false,
    });

    socketRef.current.on("connection", (socket) => {
      console.log("connected", socket);
    });
    socketRef.current.on("disconnect", console.log);
    socketRef.current.on("reconnect", console.log);
    socketRef.current.on(
      "pick-two/sync",
      ({ started, winner, tiles: rawTiles, remainingTiles, version }) => {
        const tiles = TileModel.deserializePlayers(rawTiles, token.userID);

        dispatch(
          syncState({ tiles, started, winner, remainingTiles, version })
        );
      }
    );
    socketRef.current.on("pick-two/player-join", (ids) =>
      dispatch(setPlayers(ids))
    );
    socketRef.current.on("pick-two/player-leave", (ids) =>
      dispatch(setPlayers(ids))
    );
  }, [token, gameCode, history, dispatch]);

  useEffect(() => {
    if (
      !state.letters.length &&
      !state.tiles.errors.length &&
      state.started &&
      !state.winner
    ) {
      executePickTwo(gameCode, state.tiles.serialize());
    }
  }, [
    gameCode,
    state.started,
    dispatch,
    state.letters,
    state.tiles,
    state.winner,
  ]);

  const handleCopy = useCallback(() => {
    copy(gameCode);
    dispatch(setCopyLabel("Copied"));
    setTimeout(() => dispatch(setCopyLabel(`Invite Code: ${gameCode}`)), 1500);
  }, [dispatch, gameCode]);

  const handleRestart = useCallback(
    () => executeRestartGame(gameCode),
    [gameCode]
  );

  const inSelectionMode = state.selected !== null;

  let rows = new Array(state.y1 - state.y0 + 2)
    .fill(undefined)
    .map((_, i) => state.y0 - 1 + i);
  let columns = new Array(state.x1 - state.x0 + 2)
    .fill(undefined)
    .map((_, i) => state.x0 - 1 + i);

  return (
    <div className={styles.board} onContextMenu={preventEvent}>
      <div className={styles.header}>
        Players: {state.players.size}
        {state.started ? (
          <span>, Tiles: {state.remainingTiles}</span>
        ) : (
          <button className={styles.copy} type="button" onClick={handleCopy}>
            {state.copyLabel}
          </button>
        )}
      </div>
      {state.winner && (
        <div className={styles.gameOver}>
          <h1 className={styles.gameOverTitle}>Game Over</h1>
          <p className={styles.winner}>
            {state.winner === token.userID ? "You won!" : "You lost."}
          </p>
          {isAdmin && (
            <button
              className={styles.restartGame}
              type="button"
              onClick={handleRestart}
            >
              Restart
            </button>
          )}
        </div>
      )}
      {!state.started &&
        !state.winner &&
        (isAdmin ? (
          <button
            type="button"
            className={styles.startGame}
            onClick={() => executeStartGame(gameCode)}
          >
            Start Game
          </button>
        ) : (
          <p className={styles.waitingMessage}>Waiting for game to start</p>
        ))}
      <div className={styles.inner}>
        {rows.map((y) => {
          return (
            <ol className={styles.row} key={`row-${y}`}>
              {columns.map((x) => {
                let tileState = state.tiles.get(x, y);

                let visible =
                  (state.tiles.size === 0 &&
                    x === 0 &&
                    y === 0 &&
                    inSelectionMode) ||
                  !!tileState ||
                  (inSelectionMode && state.tiles.hasAdjacent(x, y));
                let hidden = !visible;

                let error =
                  !!tileState &&
                  state.tiles.errors.some((err: any) => {
                    if (err.x0 === err.x1 && err.y0 === err.y1) {
                      // single disconnected tile
                      return err.x0 === x && err.y0 === y;
                    }

                    if (err.x0 === err.x1) {
                      // vertical
                      return err.x0 === x && err.y0 <= y && y < err.y1;
                    }

                    if (err.y0 === err.y1) {
                      return err.y0 === y && err.x0 <= x && x < err.x1;
                    }

                    return false;
                  });

                return (
                  <Square
                    key={`${x},${y}`}
                    error={error}
                    x={x}
                    y={y}
                    tile={tileState}
                    selected={state.selected}
                    dispatch={dispatch}
                    hidden={hidden}
                  />
                );
              })}
            </ol>
          );
        })}
      </div>

      <ol
        className={clsx(styles.hand, {
          [styles.hidden]: !state.started,
        })}
      >
        {[...state.letters.values()].map((tile) => (
          <Tile
            selected={state.selected}
            tile={tile}
            dispatch={dispatch}
            key={tile.id}
          />
        ))}
      </ol>
    </div>
  );
}
