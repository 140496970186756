export class Tile {
  constructor(id, letter) {
    this.id = id;
    this.letter = letter;
  }

  get points() {
    return Tile.pointsPerLetter[this.letter];
  }

  serialize() {
    return {
      id: this.id,
      letter: this.letter,
      type: "Tile",
    };
  }

  static deserialize(obj) {
    return new Tile(obj.id, obj.letter);
  }
}

Tile.distribution = {
  A: 16,
  B: 3,
  C: 3,
  D: 6,
  E: 22,
  F: 3,
  G: 4,
  H: 4,
  I: 16,
  J: 2,
  K: 4,
  L: 6,
  M: 4,
  N: 9,
  O: 14,
  P: 3,
  Q: 2,
  R: 9,
  S: 8,
  T: 9,
  U: 8,
  V: 3,
  W: 3,
  X: 2,
  Y: 3,
  Z: 2,
  "*": 12,
};

Tile.pointsPerLetter = {
  A: 5,
  B: 10,
  C: 10,
  D: 10,
  E: 5,
  F: 10,
  G: 10,
  H: 10,
  I: 5,
  J: 10,
  K: 10,
  L: 10,
  M: 10,
  N: 10,
  O: 5,
  P: 10,
  Q: 25,
  R: 10,
  S: 10,
  T: 10,
  U: 5,
  V: 10,
  W: 10,
  X: 25,
  Y: 10,
  Z: 25,
  "*": 5,
};

Tile.serializePlayers = (game) =>
  Object.keys(game.hands)
    .map((playerID) => [
      playerID,
      game.hands[playerID].map((t) => [t.id, t.letter]),
    ])
    .reduce((acc, x) => {
      acc[x[0]] = x[1];
      return acc;
    }, {});

Tile.deserializePlayers = (playerMap, playerID) =>
  playerMap?.[playerID]?.map((x) => new Tile(x[0], x[1]));
