import styles from "./index.module.scss";
import clsx from "clsx";
import { selectSquare, selectTile, returnToHand } from "../Board/boardActions";
import React, { Dispatch, useCallback, useRef } from "react";
import { useOnClickOutside } from "../../helpers/useOnClickOutside";
// @ts-ignore
import { Tile as TileModel } from "@pick-two/lib/Tile.js";

export function Square({
  x,
  y,
  tile,
  dispatch,
  hidden,
  selected,
  error = false,
}: {
  x: number;
  y: number;
  tile: TileModel;
  dispatch: Dispatch<any>;
  hidden: boolean;
  selected: string | null;
  error: boolean;
}) {
  const ref = useRef<HTMLLIElement>(null);

  useOnClickOutside(
    () => dispatch(selectTile(null)),
    ref,
    !!tile && selected === tile?.id && !hidden
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (hidden) {
        return;
      }

      e.preventDefault();

      if (selected) {
        return dispatch(
          selectSquare({
            x,
            y,
          })
        );
      }

      return dispatch(selectTile(tile.id));
    },
    [dispatch, x, y, tile, hidden, selected]
  );

  const handleRightClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      if (tile) {
        dispatch(returnToHand(tile.id));
      }
    },
    [tile, dispatch]
  );

  return (
    <li
      ref={ref}
      className={clsx(styles.square, {
        [styles.hidden]: hidden,
        [styles.moveable]: !!tile,
        [styles.selected]: !!tile && selected === tile.id,
        [styles.error]: error,
      })}
      onContextMenu={handleRightClick}
      onClick={handleClick}
    >
      {tile?.letter}
    </li>
  );
}
