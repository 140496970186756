import { Dispatch, useEffect } from "react";
import { useHistory } from "react-router";
import { setToken } from "../../globalActions";
import jwtDecode from "jwt-decode";

export type TokenData = {
  roomCode: string;
  userID: string;
  admin: boolean;
};

export function Token({ dispatch }: { dispatch: Dispatch<any> }) {
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has("token")) {
      const token = searchParams.get("token");

      if (!token) {
        return;
      }

      const parsedToken: TokenData = jwtDecode(token);

      dispatch(setToken(searchParams.get("token")));
      history.push(`/play/${parsedToken.roomCode}`);
    }
  }, [dispatch, history]);

  return <div />;
}
